
export default {
  name: 'ChoosePlan',
  components: {
    SignInSignUp: () => import('../SignInSignUp.vue')
  },
  props: ['planes', 'title', 'description'],
  data() {
    return {
      selectedCountry: null,
      countries: [
        {
          id: 10,
          name: 'Australia',
          country_code: 'AU',
          code: 61,
          currency: 'AUD',
          flag: 'au',
          currency_symbol: '$',
          paymentSupported: 1
        },
        {
          id: 31,
          name: 'Canada',
          country_code: 'CA',
          code: 1,
          currency: 'CAD',
          flag: 'ca',
          currency_symbol: '$',
          paymentSupported: 1
        },
        {
          id: 81,
          name: 'India',
          country_code: 'IN',
          code: 91,
          currency: 'INR',
          flag: 'in',
          currency_symbol: '₹',
          paymentSupported: 1
        },
        {
          id: 170,
          name: 'Singapore',
          country_code: 'SG',
          code: 65,
          currency: 'SGD',
          flag: 'sg',
          currency_symbol: '$',
          paymentSupported: 1
        },
        {
          id: 200,
          name: 'United Arab Emirates',
          country_code: 'AE',
          code: 971,
          currency: 'AED',
          flag: 'ae',
          currency_symbol: 'د.إ',
          paymentSupported: 1
        },
        {
          id: 201,
          name: 'United Kingdom',
          country_code: 'GB',
          code: 44,
          currency: 'GBP',
          flag: 'gb',
          currency_symbol: '£',
          paymentSupported: 1
        },
        {
          id: 650,
          name: 'Austria',
          country_code: 'AT',
          code: 43,
          currency: 'EUR',
          flag: 'eu',
          currency_symbol: '€',
          paymentSupported: 1
        },
        {
          id: 655,
          name: 'Belgium',
          country_code: 'BE',
          code: 32,
          currency: 'EUR',
          flag: 'eu',
          currency_symbol: '€',
          paymentSupported: 1
        },
        {
          id: 685,
          name: 'Cyprus',
          country_code: 'CY',
          code: 357,
          currency: 'EUR',
          flag: 'eu',
          currency_symbol: '€',
          paymentSupported: 1
        },
        {
          id: 695,
          name: 'Estonia',
          country_code: 'EE',
          code: 372,
          currency: 'EUR',
          flag: 'eu',
          currency_symbol: '€',
          paymentSupported: 1
        },
        {
          id: 700,
          name: 'Finland',
          country_code: 'FI',
          code: 358,
          currency: 'EUR',
          flag: 'eu',
          currency_symbol: '€',
          paymentSupported: 1
        },
        {
          id: 701,
          name: 'France',
          country_code: 'FR',
          code: 33,
          currency: 'EUR',
          flag: 'eu',
          currency_symbol: '€',
          paymentSupported: 1
        },
        {
          id: 706,
          name: 'Germany',
          country_code: 'DE',
          code: 49,
          currency: 'EUR',
          flag: 'eu',
          currency_symbol: '€',
          paymentSupported: 1
        },
        {
          id: 709,
          name: 'Greece',
          country_code: 'GR',
          code: 30,
          currency: 'EUR',
          flag: 'eu',
          currency_symbol: '€',
          paymentSupported: 1
        },
        {
          id: 717,
          name: 'Hong Kong',
          country_code: 'HK',
          code: 852,
          currency: 'HKD',
          flag: 'hk',
          currency_symbol: '元',
          paymentSupported: 1
        },
        {
          id: 724,
          name: 'Ireland',
          country_code: 'IE',
          code: 353,
          currency: 'EUR',
          flag: 'eu',
          currency_symbol: '€',
          paymentSupported: 1
        },
        {
          id: 726,
          name: 'Italy',
          country_code: 'IT',
          code: 39,
          currency: 'EUR',
          flag: 'eu',
          currency_symbol: '€',
          paymentSupported: 1
        },
        {
          id: 737,
          name: 'Latvia',
          country_code: 'LV',
          code: 371,
          currency: 'EUR',
          flag: 'eu',
          currency_symbol: '€',
          paymentSupported: 1
        },
        {
          id: 743,
          name: 'Lithuania',
          country_code: 'LT',
          code: 370,
          currency: 'EUR',
          flag: 'eu',
          currency_symbol: '€',
          paymentSupported: 1
        },
        {
          id: 744,
          name: 'Luxembourg',
          country_code: 'LU',
          code: 352,
          currency: 'EUR',
          flag: 'eu',
          currency_symbol: '€',
          paymentSupported: 1
        },
        {
          id: 749,
          name: 'Malaysia',
          country_code: 'MY',
          code: 60,
          currency: 'MYR',
          flag: 'my',
          currency_symbol: 'RM',
          paymentSupported: 1
        },
        {
          id: 752,
          name: 'Malta',
          country_code: 'MT',
          code: 356,
          currency: 'EUR',
          flag: 'mt',
          currency_symbol: '€',
          paymentSupported: 1
        },
        {
          id: 769,
          name: 'Netherlands',
          country_code: 'NL',
          code: 31,
          currency: 'EUR',
          flag: 'nl',
          currency_symbol: '€',
          paymentSupported: 1
        },
        {
          id: 772,
          name: 'New Zealand',
          country_code: 'NZ',
          code: 64,
          currency: 'NZD',
          flag: 'nz',
          currency_symbol: 'NZ$',
          paymentSupported: 1
        },
        {
          id: 790,
          name: 'Portugal',
          country_code: 'PT',
          code: 351,
          currency: 'EUR',
          flag: 'pt',
          currency_symbol: '€',
          paymentSupported: 1
        },
        {
          id: 804,
          name: 'Saudi Arabia',
          country_code: 'SA',
          code: 966,
          currency: 'SAR',
          flag: 'sa',
          currency_symbol: 'ر.س',
          paymentSupported: 1
        },
        {
          id: 810,
          name: 'Slovakia',
          country_code: 'SK',
          code: 421,
          currency: 'EUR',
          flag: 'sk',
          currency_symbol: '€',
          paymentSupported: 1
        },
        {
          id: 814,
          name: 'South Africa',
          country_code: 'ZA',
          code: 27,
          currency: 'ZAR',
          flag: 'za',
          currency_symbol: 'R',
          paymentSupported: 1
        },
        {
          id: 817,
          name: 'Spain',
          country_code: 'ES',
          code: 34,
          currency: 'EUR',
          flag: 'es',
          currency_symbol: '€',
          paymentSupported: 1
        },
        {
          id: 841,
          name: 'United States',
          country_code: 'US',
          code: 1,
          currency: 'USD',
          flag: 'us',
          currency_symbol: '$',
          paymentSupported: 1
        }
      ],
      defaultCountry: { currency: 'IN', country_code: 'IN', data: [] },
      countryCode: 'IN',
      isCountryDetected: true,
      loading: false,
      plans: [],
      isSigninSignupModel: false
    };
  },
  mounted() {
    // const res = await this.$api.countries.getPaymentSupportedCountries();
    // this.countries = res || [];

    this.fetchGeoLocationData();
  },
  methods: {
    async fetchGeoLocationData() {
      try {
        const res = await this.$api.geoLocationService.get();

        // const res = await this.$axios.$get(process.env.GEO_LOCATION_URL);
        if (res.country_code) {
          this.countryCode = res.country_code;
        }
      } catch (error) {
        this.countryCode = 'IN';
        console.log('Error==>', error);
      }
      this.findCountry(this.countryCode);
    },
    onSelectFreeTrial() {
      this.isSigninSignupModel = true;
    },
    closeSignInSignUp() {
      this.isSigninSignupModel = false;
    },
    async findCountry(countryCode) {
      this.loading = true;

      const defaultCountry = this.countries.find(
        row => row.country_code === countryCode
      );
      if (defaultCountry) {
        this.defaultCountry = defaultCountry;
        this.countryCode = countryCode;
      } else {
        this.defaultCountry = this.countries.find(
          row => row.country_code === 'IN'
        );
        this.countryCode = 'IN';
      }

      const res = await this.$api.plans.find({
        controller: 'plans-by-country',
        countryId: this.defaultCountry.id
      });

      this.plans = res.map(plan => {
        let planRate = { rate: 0 };
        if (plan.planRates.length) {
          planRate = plan.planRates[0];
        }
        let updated = { ...plan, ...planRate };

        updated.rate = updated.rate.toFixed(2);
        updated.original = updated.rate;
        updated.discount = 0;

        return updated;
      });

      this.loading = false;
    }
  }
};
