
export default {
  props: [
    "question",
    "specification",
    "checkAnswer",
    "reset",
    "hideCheckContainer",
    "resetQuestion"
  ],
  components: {
    MultipleChoice: () =>
      import("~/components/question_types/MultipleChoice.vue"),
    MultiSelect: () => import("~/components/question_types/MultiSelect.vue"),
    MatchTheOneToOne: () =>
      import("~/components/question_types/MatchTheOneToOne.vue"),
    MatchTheOneToMany: () =>
      import("~/components/question_types/MatchTheOneToMany.vue"),
    TableType: () => import("~/components/question_types/TableType.vue"),
    GridPattern: () => import("~/components/question_types/GridPattern.vue"),
    CorrectTheSentence: () =>
      import("~/components/question_types/CorrectTheSentence.vue"),
    BoxType: () => import("~/components/question_types/BoxType.vue"),
    BoxPuzzleType: () =>
      import("~/components/question_types/BoxPuzzleType.vue"),
    FillInTheBlanks: () =>
      import("~/components/question_types/FillInTheBlanks.vue"),
    CalculatorType: () =>
      import("~/components/question_types/CalculatorType.vue"),
    ClockType: () => import("~/components/question_types/ClockType.vue"),
    DigitType: () => import("~/components/question_types/DigitType.vue"),
    Crossword: () => import("~/components/question_types/Crossword.vue"),
    Rearrange: () => import("~/components/question_types/Rearrange.vue")
  },
  watch: {
    resetQuestion: function(newVal, oldVal) {
      if (newVal) {
        this.componentKey += 10;
        this.$emit("resetRefreshQuestion");
      }
    }
  },
  data() {
    return {
      componentKey: 0
    };
  },
  mounted() {
    this.componentKey = `${this.question.set_id}-${this.question.id}`;
  },
  methods: {
    forwardAnswer(options) {
      this.$emit("onSubmit", options);
    },
    resetKey() {
      this.componentKey = `${this.componentKey}-1`;
      this.$emit("resetRefreshQuestion");
    }
  }
};
