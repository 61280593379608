
import { mdiArrowRightCircleOutline, mdiArrowLeftCircleOutline } from "@mdi/js";

export default {
  name: "HowItWorksTimeline",
  props: ["textPosition"],
  components: {
    TimelineImage: () =>
      import("@/components/HowItWorksTimeline/TimelineImage.vue"),
    TimelineText: () =>
      import("@/components/HowItWorksTimeline/TimelineText.vue")
  },
  data() {
    return {
      icons: {
        mdiArrowRightCircleOutline,
        mdiArrowLeftCircleOutline
      },
      timelines: [
        {
          title: "Download Our App",
          text:
            "Enjoy BrainGymJr on-the-go in Android and iOS or your preferred web browser",
          image: "1.png"
        },
        {
          title: "Sign Up",
          text: "Get started without any payment details",
          image: "2.png"
        },
        {
          title: "Get Your Level",
          text: "Start at a difficulty level that suits you",
          image: "3.png"
        },
        {
          title: "Practice and Build Strong Habits",
          text:
            "Get new questions daily in 16 different formats and audio stories",
          image: "4.png"
        },
        {
          title: "Appear on Leaderboards",
          text:
            "Keep answering correctly to get recognised and appear on Leader boards",
          image: "5.png"
        },
        {
          title: "Earn Badges & Certificates",
          text: "Get Rewarded on your learning milestones",
          image: "6.png"
        },
        {
          title: "Redeem Points for Rewards",
          text: "Get exclusive discounts on Games, Books and other merchandise",
          image: "7.png"
        },
        {
          title: "Get Personalised Speed and Accuracy Reports",
          text: "Track your child's progress and identify areas of improvement",
          image: "8.png"
        },
        {
          title: "Learn & Level Up",
          text: "Take assessments and move up to higher levels of difficulty",
          image: "9.png"
        }
      ]
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    }
  },
  methods: {
    getElements(index) {
      return index % 2 === 0
        ? ["text", "separator", "image"]
        : ["image", "separator", "text"];
    }
  }
};
