
import NewsCard from "./NewsCard.vue";
import { mdiArrowLeftBold, mdiArrowRightBold } from "@mdi/js";
export default {
  name: "NewsSection",
  data() {
    return {
      lists: [],
      page: 0,
      icons: {
        mdiArrowLeftBold,
        mdiArrowRightBold
      }
    };
  },
  components: {
    NewsCard
  },
  props: ["title", "news", "description"],
  computed: {
    columnSize() {
      return 4;
    }
  },
  methods: {
    chunk(array, size) {
      const chunked_arr = [];
      let index = 0;
      while (index < array.length) {
        chunked_arr.push(array.slice(index, size + index));
        index += size;
      }
      return chunked_arr;
    },
    navigate(action) {
      // let clmn = this.$vuetify.breakpoint.xs ? 1 : 3
      if (action === "next") {
        this.page += 1;
      } else {
        this.page -= 1;
      }
    }
  },
  created() {
    setTimeout(() => {
      let clmnSize = this.$vuetify.breakpoint.xs ? 1 : 4;
      this.lists = this.chunk(this.news, clmnSize);
    }, 2000);
  }
};
