
import user_mixin from '~/mixins/user_mixin';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { mdiClose } from '@mdi/js';
export default {
  components: {
    SignInSignUp: () => import('./SignInSignUp.vue')
  },
  mixins: [user_mixin, validationMixin],
  data() {
    return {
      icons: {
        mdiClose
      },
      isSignInModel: false,
      isAskDoubtPopupOpen: false,
      questionTypes: ['Typo', 'Query', 'Suggestion', 'Incorrect question'],
      selectedType: 'Query',
      message: '',
      alertOpen: false,
      alertMessage: '',
      valid: false,
      loading: false
    };
  },
  validations: {
    message: { required }
  },
  computed: {
    messageError() {
      const errors = [];
      if (!this.$v.message.$dirty) return errors;
      !this.$v.message.required && errors.push('Message is required');
      return errors;
    }
  },
  props: ['label', 'selectedPath', 'questionId', 'module'],
  methods: {
    closeAskDoubtPopup() {
      this.isAskDoubtPopupOpen = false;
      this.message = '';
      this.selectedType = 'Query';
      this.$v.message.$reset();
      this.$emit('close');
    },
    closeSignInSignUp() {
      this.isSignInModel = false;
      this.$emit('close');
    },
    askDoubt() {
      if (!this.$auth.loggedIn) {
        this.isSignInModel = true;
        this.isAskDoubtPopupOpen = false;
      } else {
        this.isSignInModel = false;
        this.isAskDoubtPopupOpen = true;
      }
    },
    validate() {
      return this.$refs.form.validate();
    },
    async askQuery() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      if (this.validate()) {
        this.loading = true;
        try {
          await this.$api.askDoubt.create({
            childId: this.getChild.id,
            parentEmail: this.$auth.user.email,
            questionId: this.questionId,
            childQueryType: this.selectedType,
            message: this.message,
            module: this.module
          });
          this.alertOpen = true;
          this.alertMessage =
            "We have received your request, we'll contact you soon.";
          this.closeAskDoubtPopup();
        } catch (e) {
          console.log(e);
        } finally {
          this.loading = false;
        }
      }
    }
  },
  created() {
    this.askDoubt();
  }
};
