import { render, staticRenderFns } from "./index.vue?vue&type=template&id=d0987e44"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=d0987e44&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HowItWorksTimeline: require('/codebuild/output/src436006890/src/client/components/HowItWorksTimeline/HowItWorksTimeline.vue').default,HomePageTitle: require('/codebuild/output/src436006890/src/client/components/home/HomePageTitle.vue').default})
