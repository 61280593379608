
import { mdiRefresh, mdiChevronRight } from '@mdi/js';
export default {
  props: [
    'specification',
    'data',
    'footerOptions',
    'askDoubt',
    'hideAskFriendButton',
    'hideAnswerExplaination',
    'hideNextButton',
    'forceReset',
    'module',
    'hideCheckContainer',
    'isAdminPreview'
  ],
  components: {
    QuestionType: () => import('~/components/question_types/QuestionType'),
    AskDoubt: () => import('~/components/AskDoubt'),
    AskFriend: () => import('~/components/Chat/AskFriend.vue')
  },
  data() {
    return {
      isOverflowingQuestion: [1, 3, 4, 11, 12, 14, 15].includes(
        this.data.type_id
      ),
      checkAnswer: false,
      isAskDoubtPopupOpen: false,
      resetSupportedTypes: [
        'Match the following One to one',
        'Match the following One to Many'
      ],
      reset: false,
      resetDialog: false,
      isSinginSingupModel: false,
      selectedPath: 'home',
      loading: false,
      explanationImageDialog: false,
      explanationImageUrl: '',
      resetQuestion: false,
      askFriendDialog: false,
      askDoubtDialog: false,
      icons: {
        mdiRefresh,
        mdiChevronRight
      },
      answerStatus: null // wrong or correct
    };
  },
  computed: {
    questionImageUrl() {
      if (this.specification && this.specification.questionImage) {
        let img = this.specification.questionImage;
        if (img.url && img.url.startsWith('blob:http')) {
          return img.url;
        }
        return `/${img.url}`;
      }
      return '#';
    },
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    },
    isUserLoggedIn() {
      const user = this.$auth.user;
      return user ? true : false;
    }
  },
  methods: {
    onSubmit(options) {
      this.answerStatus = options.status;
      this.$emit('onSubmit', options);
    },
    showExpImage(url) {
      this.explanationImageUrl = url;
      this.explanationImageDialog = true;
    },
    nextQuestion() {
      this.loading = true;
      this.answerStatus = null;
      this.$emit('nextQuestion');
      this.checkAnswer = false;
    },
    closeAskDoubtPopup() {
      this.isAskDoubtPopupOpen = false;
    },
    showAskDoubt() {
      if (!this.$auth.loggedIn) {
        this.isSinginSingupModel = true;
        this.isAskDoubtPopupOpen = false;
      } else {
        this.isSinginSingupModel = false;
        this.isAskDoubtPopupOpen = true;
      }
    },
    handleQuestionReset() {
      this.resetQuestion = false;
    }
  },
  watch: {
    data(v) {
      if (v) this.loading = false;
    },
    forceReset(v) {
      if (v) {
        this.checkAnswer = false;
        this.$emit('forceResetDone');
      }
    },
    checkAnswer(v) {
      if (v) this.$emit('onAnswerSubmit'); // To handle scenario in story when answer is not selected
    }
  }
};
