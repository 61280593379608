
export default {
  name: "SubjectTabs",
  data() {
    return {
      subjects: [
        {
          id: 1,
          name: "Real world",
          description:
            "Real-world problems that enhance a childs perception of the world",
          coverUrl: "/upload/realworld.png"
        },
        {
          id: 2,
          name: "Maths",
          description:
            "Improve Logical thinking and reasoning skills via maths",
          coverUrl: "/upload/math-subject.png"
        },
        {
          id: 3,
          name: "English",
          description:
            "Sharpen cognitive and analytical skills and a better grasp of the language",
          coverUrl: "/upload/english-subject-icon.png"
        }
      ]
    };
  },
  mounted() {
    this.getSubjects();
  },
  methods: {
    async getSubjects() {
      // let subjects = await this.$api.subjects.find({});
      // this.subjects = subjects.data;
      if (this.subjects[0] !== undefined) {
        this.onSubjectSelect(this.subjects[0]);
      }
    },
    onSubjectSelect(subject) {
      this.$emit("subjectSelectedHandler", subject);
    }
  }
};
