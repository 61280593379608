
export default {
  name: "HomePageTitle",
  head() {
    return {
      title: "Daily Brain Exercises for Children | BrainGymJr",
      meta: [
        {
          hid: "description",
          name: "description",
          content:
            "Custom puzzles and worksheets for kids aged 5+ in English, Math and Real World Concepts. New fun and educational questions daily. Earn points for correct answers and redeem for discounts on toys, games, books, and more. Start learning today!"
        }
      ]
    };
  }
};
