export const MODULES = {
  SIGNUP: 'signup',
  LOGIN: 'login',
  MY_KIDS: 'my-kids',
  STORIES: 'stories',
  DAILY_QUESTION: 'daily-question',
  PRACTICE_QUESTION: 'practice-question'
};

export default {
  methods: {
    reportError({ tags, error, extraData }) {
      this.$sentry.withScope(scope => {
        scope.setTags(tags);
        scope.setExtras(extraData);
        this.$sentry.captureException(error);
      });
    }
  }
};
