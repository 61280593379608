
import CustomerCard from "./CustomerCard.vue";
import { mdiArrowLeftBold, mdiArrowRightBold } from "@mdi/js";

export default {
  name: "HappyCustomerSection",
  data() {
    return {
      columns: 3,
      lists: [],
      page: 0,
      icons: {
        mdiArrowLeftBold,
        mdiArrowRightBold
      }
    };
  },
  components: {
    CustomerCard
  },
  props: ["imgSrc", "title", "description", "customers"],
  methods: {
    chunk(array, size) {
      const chunked_arr = [];
      let index = 0;
      while (index < array.length) {
        chunked_arr.push(array.slice(index, size + index));
        index += size;
      }
      return chunked_arr;
    },
    navigate(action) {
      // let clmn = this.$vuetify.breakpoint.xs ? 1 : 3
      if (action === "next") {
        this.page += 1;
      } else {
        this.page -= 1;
      }
    },
    goToContactPage() {
      this.$router.replace("/contact-us");
    }
  },
  mounted() {
    setTimeout(() => {
      let clmnSize = this.$vuetify.breakpoint.xs ? 1 : 3;
      this.lists = this.chunk(this.customers, clmnSize);
    }, 2000);
  }
};
