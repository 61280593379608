
import { mapState } from "vuex";
export default {
  name: "PricingCard",
  components: {
    SignInSignUp: () => import("./SignInSignUp.vue")
  },
  props: {
    data: {
      type: Object
    },
    selected: Function,
    country: {
      type: Object
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isHovered: true,
      isSigninSignupModel: false,
      selectedPath: null,
      defaultCountry: { currency: "INR", flag: "in" },
      defaultPlan: 0
    };
  },
  methods: {
    closeSignInSignUp() {
      this.isSigninSignupModel = false;
    },
    goToPlan(plan) {
      if (this.paymentDisable) {
        this.$notifier.showMessage({
          content: this.paymentDisableMessage,
          color: "error"
        });
        return;
      }

      if (!this.$auth.loggedIn) {
        this.defaultPlan = plan.plan_id;
        this.isSigninSignupModel = true;
      } else {
        this.$emit("close", plan);
      }
    }
  },
  computed: {
    ...mapState("payment", ["paymentDisable", "paymentDisableMessage"]),
    ...mapState("global", ["userType"]),
    isMobile() {
      return this.$vuetify.breakpoint.xs === true;
    }
  }
};
