
import HeroSection from "../components/home/HeroSection.vue";
// import FaqSection from "../components/home/FaqSection.vue";
import HeroBlock from "../components/home/HeroBlock.vue";
// import HomeFooter from "../components/home/HomeFooter.vue";
import HeroContent from "../components/home/HeroContent.vue";
import HappyCustomerSection from "../components/home/HappyCustomerSection.vue";
import NewsSection from "../components/home/NewsSection.vue";
import ChoosePlan from "../components/home/ChoosePlan.vue";
import TrySampleQuestion from "../components/home/TrySampleQuestion.vue";

export default {
  layout: "home",
  components: {
    HomePageTitle: () => import("@/components/home/HomePageTitle.vue"),
    HeroSection,
    FaqSection: () => import("@/components/home/FaqSection.vue"),
    HomeFooter: () => import("../components/home/HomeFooter.vue"),
    HeroContent,
    HappyCustomerSection,
    NewsSection,
    HeroBlock,
    ChoosePlan,
    TrySampleQuestion,
    HowItWorksTimeline: () =>
      import("@/components/HowItWorksTimeline/HowItWorksTimeline.vue"),
    WhyBraingymjr: () => import("@/components/home/WhyBraingymjr.vue")
  },
  data() {
    return {
      whyWeNeedSection: {
        imgSrc: require("~/assets/images/HowBGJrHelp.png"),
        title: "How can BrainGymJr help your child?",
        description:
          "<strong style='font-weight:500'>Teaching Children ‘How’ to Think, not ‘What’ to Think:</strong> Today, children are largely told what to do. Most formal or informal education is structured, and instruction based. In order to hone critical thinking and application in real life, it is important to encourage solution orientation and problem solving as an approach. BrainGymJr focuses on fun, game based, real life puzzles and situations.<br><br><strong style='font-weight:500'>Makes Thinking a Daily Habit:</strong> Disciplined children make successful adults. It is essential to engage children daily; nudging them to apply their mind as a way of life. BrainGymJr ensures fresh exercises to be solved daily, making a habit akin to reading a newspaper! The habit is positively reinforced through timers, badges, leaderboards and overall progress across levels."
      },
      planeSection: {
        title: "Choose Your Plan",
        description: "Choose the plan that best suits your child.",
        planes: [
          {
            title: "Starter Pack",
            howLong: "Daily challenges for One month",
            price: 2.05,
            id: 1,
            planPrice: 0,
            currencySymbol: "$",
            isBestValue: false
          },
          {
            title: "Booster Pack",
            howLong: "Daily challenges for Three months",
            price: 5.49,
            id: 2,
            planPrice: 0,
            currencySymbol: "$",
            isBestValue: false
          },
          {
            title: "Turbo Pack",
            howLong: "Daily challenges for Six months",
            price: 8.25,
            id: 3,
            planPrice: 0,
            currencySymbol: "$",
            isBestValue: true
          },
          {
            title: "Jet Pack",
            howLong: "Daily challenges for Twelve months",
            price: 13.76,
            id: 4,
            planPrice: 0,
            currencySymbol: "$",
            isBestValue: false
          }
        ]
      },
      customerSection: {
        title: "Trusted by Many Parents Just like Yourself",
        description:
          "Happy children make happy parents. Do not take our word for it, hear from other parents below.",
        customers: [
          {
            id: "0",
            name: "Soumya Agarwal",
            city: "Mom",
            rating: "4.1",
            body:
              "“The questions and exercises are phenomenal. As parents, we always looked for worksheets of various types. This is so much better. We get customized level of difficulty for every child. Also, instant answers and explanation helps in learning. Our daughter enjoys winning badges and wants to now appear on the leader-board !”"
          },
          {
            id: "2",
            name: "Radhika Mehta",
            city: "Mom",
            rating: "4.3",
            body:
              "“Great concept. The fact that online time is now used to solve Maths, English and Real-world questions is so much better. In addition, the positive encouragement to practice and win points and move up levels keeps my son very engaged. This is better than trying to get my son to do worksheets or learn beyond school studies.”"
          },
          {
            id: "3",
            name: "Varun Jain",
            city: "Father",
            rating: "4.4",
            body:
              "“You are making concept learning interesting and simple for children. It is relevant as it includes real life situations that my son deals with. The fresh questions sent daily help cultivate a habit without getting bored. I am very happy with the variety that ranges from crosswords to sudoku to match the columns – all online, with immediate answers. This also helps children deal with new formats and learn quickly.”"
          },
          {
            id: "4",
            name: "Mona Jain",
            city: "Mom",
            rating: "4.4",
            body:
              "“BrainGym Jr has been a great experience for my son. Vyom, my 6 year old looks forward to the challenges on a daily basis and has even started waiting for them. He feels that he is answering questions like older people and has started to read the time and add the bill when he goes shopping with me. This subscription is a must try for any parent who is looking to develop logical and reasoning skills for their child.”"
          }
        ]
      },
      newsSection: {
        title: "Education beyond Academics",
        description:
          "Research confirms that systematic enablement and daily impetus to children in developing real life skills such as logic, reasoning, maths and language plays a significant role in future success!",
        news: [
          {
            id: "0",
            title: "The 'Correct’ Age for Logical Thinking",
            imgSrc: "correct-age-for-logical-thinking.jpg",
            url:
              "https://www.braingymjr.com/blog/the-different-stages-for-logical-thinking/",
            body:
              "Logical thinking skills help children establish cause and effect relationships that help them be more successful at school & play. Learn How!"
          },
          {
            id: "1",
            title: "Can We Teach Children How To Think?",
            imgSrc: "tech-children-how-to-think.jpg",
            url:
              "https://www.braingymjr.com/blog/developing-thinking-skills-in-children/",
            body:
              "Thinking is a habit. Children need regular opportunity to explore & practice using all thinking hats to help make better decisions more often."
          },
          {
            id: "2",
            title: "Help Your Children Speak For Themselves!",
            imgSrc: "help-your-children.jpg",
            url: "https://www.braingymjr.com/blog/teach-children-to-speak-up/",
            body:
              "Constant shyness can reduce the quality of a child’s life while assertiveness can help children with relationships. Learn tips to speak up."
          },
          {
            id: "3",
            title: "Do Video Games Help Children?",
            imgSrc: "video-games.jpg",
            url:
              "https://www.braingymjr.com/blog/video-games-and-the-benefits-for-children/",
            body:
              "Past studies have shown negative effects of games but today there are positive benefits too. If screen time is limited, video games are good."
          },
          {
            id: "0",
            title: "Benefits of Gamifying the Learning Process!",
            imgSrc: "benifits-of-gamyfie-with-learning.jpg",
            url:
              "https://www.braingymjr.com/blog/gamifying-the-learning-process-benefits/",
            body:
              "Gamifying the learning process is the use of tools like rewards, challenges & leaderboards. It leaves a deeper impact and makes learning fun."
          },
          {
            id: "1",
            title: "Influencing Your Child's IQ",
            imgSrc: "infulacing-child-iq.jpg",
            url: "https://www.braingymjr.com/blog/improving-childs-iq-levels/",
            body:
              "Factors that influence a child's IQ include home environment, parenting, education, availability of learning resources, and even nutrition."
          },
          {
            id: "2",
            title: "How to Negotiate with Children",
            imgSrc: "how-to-negotiate.jpg",
            url:
              "https://www.braingymjr.com/blog/tips-to-negotiate-with-children/",
            body:
              "Few tips to negotiate with children gently, without friction & frustration to help hear and respond to your child in a thoughtful manner."
          },
          {
            id: "3",
            title: "Five Ways to Improve Your Childs Decision Making Skills",
            imgSrc: "five-ways-to-improve-child-decision-making.jpg",
            url:
              "https://www.braingymjr.com/blog/ways-to-improve-childs-decision-making/",
            body:
              "It is important to help build your childs decision making skills from an early age, by giving them lots of practice in an appropriate way."
          }
        ]
      },
      howWorks: [
        {
          imageSrc: "BGJrPanel1.webp",
          bgColor: "#525FFB",
          textColor: "white",
          title: "BrainGymJr teaches children ‘How’ to Think through",
          caption: "Figure out your child’s level and pick a plan accordingly",
          captions: [
            "APPLICATION and PROBLEM SOLVING online",
            "CORE CONCEPTS of Math, English and Real-World skills",
            "16 engaging formats making LEARNING FUN",
            "TAILORED level of DIFFICULTY for your child",
            "POSITIVE REINFORCEMENT to solve every day"
          ]
        },
        {
          imageSrc: "BGJrPanel2.webp",
          bgColor: "#E9F0FF",
          title: "How can we get started?",
          caption:
            "It takes only a few minutes to fill the form and complete the registration for your child",
          captions: [
            "ONE STEP Sign up and Payment for chosen PLAN OPTION",
            "Get default level basis age for AGE GROUP 5-11 YEARS",
            "Take OPTIONAL free assessment for CUSTOMIZED DIFFICULTY levels",
            "Receive FRESH NEW DAILY EXERCISES for Math, English and Real-world Skills",
            "Unlimited PRACTICE QUESTIONS with INSTANT SOLUTIONS online"
          ]
        },
        {
          imageSrc: "BGJrPanel3.webp",
          bgColor: "#FFE7AA",
          title: "What happens once you subscribe?",
          caption:
            "Now you are ready to start the exciting learning journey with your child.",
          captions: [
            "LOG IN EVERYDAY for your daily boost to BRAIN POWER",
            "DIY (Do it yourself) online – Get. Click. Go Anytime, Anywhere",
            "Get EXTRA POINTS for solving Daily Exercises",
            "Practice to EARN MORE POINTS and JUMP LEVELS",
            "Unlock BADGES, appear on LEADERBOARDS and MOVE UP levels",
            "Get your Personalized SPEED & ACCURACY Dashboard"
          ]
        }
      ]
    };
  },
  mounted() {
    if (this.$auth.loggedIn) {
      this.$router.replace("/parents/mykids");
    }

    if (this.$route.params.goto && this.$route.params.goto === "try_sample") {
      document.getElementById("try_sample").scrollIntoView();
    }
  },
  methods: {
    isLoggedIn() {
      return this.$auth.loggedIn;
    }
  }
};
