
import { mapGetters, mapMutations, mapState } from 'vuex';
// import MixTypeAssesment from "~/components/MixTypeAssesment.vue";
// import Question from "~/components/question_types/Question";
import user_mixin from '~/mixins/user_mixin';
import moment from 'moment-timezone';
import {
  mdiStar,
  mdiChevronLeft,
  mdiAccountPlusOutline,
  mdiGamepadVariant
} from '@mdi/js';
import { events, trackEvent } from '@/lib/clevertap';
import error_reporting, { MODULES } from '../../mixins/error_reporting';
import { convertLeaderboardPoints } from '@/lib/common';

export default {
  mixins: [user_mixin, error_reporting],
  components: {
    // MixTypeAssesment: () => import("~/components/MixTypeAssesment.vue"),
    Question: () => import('~/components/question_types/Question'),
    JumpTestTimer: () => import('../jump-test/JumpTestTimer.vue')
  },
  data() {
    return {
      questionSetId: null,
      questions: [],
      currentQuestion: null,
      currentQuestionSpecification: null,
      currentQuestionIndex: 0,
      points: 0,
      currentQuestionSetPoints: 0,
      answers: [],
      checkAnswer: false,
      questionsetAvailable: true,
      isSinginSingupModel: false,
      isSelectOpponent: false,
      pastQuestionSetIds: [],
      pastTotal: 0,
      pastPassed: 0,
      totalQues: 0,
      questionStartTime: null,
      icons: {
        mdiStar,
        mdiChevronLeft,
        mdiAccountPlusOutline,
        mdiGamepadVariant
      }
    };
  },
  props: ['renderType', 'jumpTest', 'askDoubt'],
  async mounted() {
    await this.fetchNewQuestionSet();
  },
  computed: {
    ...mapState('global', ['selectedUserProfile']),
    ...mapGetters({
      subject: 'practiceQuestionSet/getSubject',
      level: 'practiceQuestionSet/getLevel',
      topic: 'practiceQuestionSet/getTopic',
      getTotalQuestionsSets: 'practiceQuestionSet/getTotalQuestionsSets',
      getTotalSummaries: 'practiceQuestionSet/getTotalSummaries',
      subjectLevels: 'childProfile/getSubjectLevels',
      practiceEventsTrackStatus: 'analytics/getPracticeEventsTrackStatus'
    }),
    changeData() {
      return [this.subject, this.level];
    },
    summaryProgress() {
      const totalSummaries = this.getTotalSummaries;
      // 37.75% > 68.75% > 100%

      if (totalSummaries === 5) {
        return '37.75%';
      } else if (totalSummaries === 10) {
        return '68.75%';
      } else if (totalSummaries === 15) {
        return '100%';
      }

      return `${(totalSummaries / 15) * 100}%`;
    },
    isMobile() {
      return this.$vuetify.breakpoint.xs === true;
    }
  },
  watch: {
    changeData: {
      handler: async function ([subject, level]) {
        if (this.renderType === 'TRY_SAMPLE') {
          this.pastQuestionSetIds = [];
          await this.fetchNewQuestionSet();
        }
      }
    }
  },
  methods: {
    async getQuestionSetsQuery() {
      let query = {
        lastAttemptedQuestionSetId: this.questionSetId,
        childId: this.renderType === 'TRY_SAMPLE' ? 0 : this.getChild.id,
        level: this.level,
        subjectId: this.subject.id,
        tags: this.getTags(),
        topicId: this.topic,
        ...(this.renderType === 'TRY_SAMPLE' && this.pastQuestionSetIds.length
          ? { pastQuestionSetIds: this.pastQuestionSetIds.join(',') }
          : {})
      };
      if (this.jumpTest) query.tags = 'Jump Test';
      return query;
    },
    async fetchNewQuestionSet() {
      let totalQuestionsSets = [...this.getTotalQuestionsSets];
      try {
        if (totalQuestionsSets.length === 0) {
          const query = await this.getQuestionSetsQuery();
          const data = await this.$api.practiceQuestionSet.find(query);
          // Assign it again to get rid of Vue's observer
          totalQuestionsSets = Object.assign([], data.questionSets);

          // No progress = 0 and 0 would bypass the condition
          if (typeof data.questionSetCounter === 'number') {
            this.setTotalSummaries(data.questionSetCounter);
          }

          if (data.totalPoints) {
            this.points += data.totalPoints;
          }
        }

        // Remove the question set from the state
        const nextQuestionSet = totalQuestionsSets.shift();
        this.setTotalQuestionsSets(totalQuestionsSets);

        this.questionSetId = nextQuestionSet.id;
        this.pastQuestionSetIds.push(this.questionSetId);
        this.questions = nextQuestionSet.questions;
        if (this.questions.length) {
          this.currentQuestionIndex = 0;
          this.currentQuestionSetPoints = 0;
          this.answers = [];
          this.nextQuestion();
        }
      } catch (err) {
        console.log(err);
        if (
          err.response &&
          err.response.status &&
          err.response.status === 400 &&
          err.response.data.message === 'No questionset available for subject'
        ) {
          this.questionsetAvailable = false;
        } else {
          this.reportError({
            tags: { module: MODULES.PRACTICE_QUESTION },
            error: err
          });
        }
      }
    },
    nextQuestion() {
      try {
        const { currentQuestionIndex, questions } = this;
        const lastQuestionInQuestionSet =
          currentQuestionIndex == questions.length && currentQuestionIndex != 0;
        if (lastQuestionInQuestionSet) {
          this.submitQuestionSet();
        } else {
          this.currentQuestionIndex = currentQuestionIndex + 1;
          this.currentQuestion = questions[this.currentQuestionIndex - 1];
          this.currentQuestionSpecification = JSON.parse(
            this.currentQuestion.specification
          );
          this.questionStartTime = new moment();
        }
        this.checkAnswer = false;
        if (this.renderType === 'TRY_SAMPLE') {
          this.questionsetAvailable = true;
        }
      } catch (err) {
        this.reportError({
          tags: { module: MODULES.PRACTICE_QUESTION },
          error: err
        });
        throw err;
      }
    },
    onSubmit(options) {
      try {
        const { points, currentQuestionSetPoints, currentQuestion } = this;
        if (options.status === 'correct') {
          this.points = points + currentQuestion.points;
          this.currentQuestionSetPoints =
            currentQuestionSetPoints + currentQuestion.points;
        }

        this.answers.push({
          questionId: currentQuestion.id,
          answer: options.answer,
          timeTaken: new moment().diff(this.questionStartTime, 'seconds')
        });
      } catch (err) {
        this.reportError({
          tags: { module: MODULES.PRACTICE_QUESTION },
          error: err
        });
        throw err;
      }
    },
    async submitQuestionSet() {
      try {
        let data = {
          questionsetId: this.questionSetId,
          answers: this.answers,
          childId: this.renderType === 'TRY_SAMPLE' ? 0 : this.getChild.id,
          ...(this.renderType === 'TRY_SAMPLE' && this.pastQuestionSetIds.length
            ? {
                pastQuestionSetIds: this.pastQuestionSetIds.join(','),
                totalPoints: this.pastTotal,
                history: { passed: this.pastPassed, totalQues: this.totalQues }
              }
            : {})
        };
        if (this.jumpTest) {
          data.subject = this.subject.id;
          data.childId = this.getChild.id;
        }

        const result = await this.$api.practiceQuestionSet.submitAnswer(data);
        this.pastTotal += result.points;
        this.totalQues += result.answerSummary.totalQues;
        this.pastPassed += result.answerSummary.correct;

        this.setTotalSummaries(Math.min(this.getTotalSummaries + 1, 15));

        if (this.$auth.loggedIn)
          this.addChildPoints(this.currentQuestionSetPoints, {
            subjectId: this.subject.id
          });

        if (!this.practiceEventsTrackStatus[this.subject.name]) {
          // Track Practice
          trackEvent(events.PRACTICE, {
            child_name: this.getChild.name,
            subject: this.subject.name,
            date: new Date(),
            questionset_id: result.questionsetId,
            passed: result.allCorrectAnswers ? 'Yes' : 'No',
            points: result.points,
            level: this.level
          });
          this.setPracticeEventsTracked({ subject: this.subject.name });
        }

        if (result.summary) {
          if (result.summary.answerSummary) {
            this.setAnswerSummary(result.summary.answerSummary);
          }
          this.setSummaryPoints(result.summary.totalPoints);

          if (this.renderType === 'TRY_SAMPLE') {
            // Track Sample
            trackEvent(events.SAMPLE_QUESTION, {
              subject: this.subject.name,
              date: new Date(),
              passed: result.allCorrectAnswers ? 'Yes' : 'No',
              points: result.summary.totalPoints,
              total_questions: result.summary.answerSummary.totalQues,
              total_correct: result.summary.answerSummary.correct,
              total_wrong:
                result.summary.answerSummary.totalQues -
                result.summary.answerSummary.correct,
              level: this.level
            });
          }
        } else {
          // Don't skip a question set if the summary is shown
          this.fetchNewQuestionSet();
        }
      } catch (err) {
        this.reportError({
          tags: { module: MODULES.PRACTICE_QUESTION },
          error: err
        });
        throw err;
      }
    },
    goToPracticeHomePage() {
      this.refreshLayout();
    },
    convertTotalPoints(points) {
      const res = convertLeaderboardPoints(points);
      return res;
    },
    getTags() {
      const { renderType } = this;
      if (renderType === 'TRY_SAMPLE') {
        return 'Sample';
      } else if (renderType === 'PRACTICE') {
        return 'Practice';
      }
      return null;
    },
    ...mapMutations({
      setSummaryPoints: 'practiceQuestionSet/setSummaryPoints',
      setAnswerSummary: 'practiceQuestionSet/setAnswerSummary',
      setTotalQuestionsSets: 'practiceQuestionSet/setTotalQuestionsSets',
      setTotalSummaries: 'practiceQuestionSet/setTotalSummaries',
      refreshLayout: 'practiceQuestionSet/refreshLayout',
      changeLevel: 'childProfile/changeLevel',
      changeSubjectLevels: 'childProfile/changeSubjectLevels',
      setPracticeEventsTracked: 'analytics/setPracticeEventsTracked'
    })
  }
};
