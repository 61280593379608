
export default {
  props: ["scrollTo"],
  data: () => ({
    selectedPath: null
  }),
  methods: {
    goToSubscribe() {
      this.$router.push({
        name: "subscribe"
      });
    },
    clickHandler() {
      if (this.scrollTo) {
        const scrollElem = document.querySelector(this.scrollTo).offsetTop;
        document
          .querySelector(".main")
          .scrollTo({ top: scrollElem - 100, behavior: "smooth" });
      }
    }
  },
  computed: {
    isLoggedIn() {
      return this.$auth.loggedIn;
    }
  }
};
