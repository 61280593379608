
import { mapGetters } from "vuex";
import { mapMutations } from "vuex";
import SubjectTabs from "../../components/practice-questionset/SubjectTabs.vue";

export default {
  layout: "default",
  data() {
    return {
      levels: [
        {
          id: 1,
          age: 5,
          month: 4,
          day: 4,
          level: 1
        },
        {
          id: 2,
          age: 5,
          month: 8,
          day: 8,
          level: 2
        },
        {
          id: 3,
          age: 6,
          month: 0,
          day: 0,
          level: 3
        },
        {
          id: 4,
          age: 6,
          month: 4,
          day: 4,
          level: 4
        },
        {
          id: 5,
          age: 6,
          month: 8,
          day: 8,
          level: 5
        },
        {
          id: 6,
          age: 7,
          month: 0,
          day: 0,
          level: 6
        },
        {
          id: 7,
          age: 7,
          month: 4,
          day: 4,
          level: 7
        },
        {
          id: 8,
          age: 7,
          month: 8,
          day: 8,
          level: 8
        },
        {
          id: 9,
          age: 8,
          month: 0,
          day: 0,
          level: 9
        },
        {
          id: 10,
          age: 8,
          month: 4,
          day: 4,
          level: 10
        },
        {
          id: 11,
          age: 8,
          month: 8,
          day: 8,
          level: 11
        },
        {
          id: 12,
          age: 9,
          month: 0,
          day: 0,
          level: 12
        },
        {
          id: 13,
          age: 9,
          month: 4,
          day: 4,
          level: 13
        },
        {
          id: 14,
          age: 9,
          month: 8,
          day: 8,
          level: 14
        },
        {
          id: 15,
          age: 9,
          month: 8,
          day: 8,
          level: 15
        }
      ],
      selectedSubject: null,
      selectedLevel: 1
    };
  },
  components: {
    SampleQuestionPointsEarned: () =>
      import(
        "../../components/practice-questionset/SampleQuestionPointsEarned.vue"
      ),
    PracticeQuestionSet: () =>
      import("../../components/practice-questionset/PracticeQuestionSet.vue"),
    SubjectTabs
  },
  async mounted() {
    // let levels = await this.$api.ageLevels.find({ $paginate: false });
    // this.levels = levels;
  },
  computed: {
    ...mapGetters({
      layout: "practiceQuestionSet/getLayout"
    })
  },
  methods: {
    ...mapMutations({
      refreshLayout: "practiceQuestionSet/refreshLayout",
      changeLevel: "practiceQuestionSet/changeLevel"
    }),
    subjectChanged(subject) {
      this.selectedSubject = subject;
      if (this.selectedLevel) {
        if (this.layout !== "points-earned") {
          this.changeLevel({
            levelId: this.selectedLevel,
            subject: this.selectedSubject
          });
        }
      }
    },
    onLevelChanged(level) {
      this.selectedLevel = level;
      this.changeLevel({
        levelId: level,
        subject: this.selectedSubject
      });
    }
  }
};
